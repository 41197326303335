<template>
  <div class="search_box">
    <input v-model="data.keyword" class="input" placeholder="Please enter" @keyup.enter="EnterKeyWord" />
    <div class="btn" @click="emit('GoSearch', data.keyword)">Search</div>
    <img class="delete" src="../../assets/images/delete.png" @click="DeleteKeyword" v-if="data.keyword" />
  </div>
</template>

<script setup>
import { defineEmits,reactive,defineProps,onActivated } from 'vue';
const data = reactive({
  keyword: props.search_text
});
const props = defineProps({
  search_text:String
});

const emit = defineEmits(['GoSearch']);

onActivated(()=>{
  data.keyword = props.search_text;
});

function EnterKeyWord(){
  emit('GoSearch', data.keyword);
}

function DeleteKeyword(){
  data.keyword = '';
  emit('GoSearch', data.keyword);
}

</script>